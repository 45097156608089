import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

const Chatbot = () => {
    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState([]);
    const userId = useRef(uuidv4());
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        AWS.config.region = process.env.REACT_APP_AWS_REGION; // Use environment variable
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, // Use environment variable
        });

        // Ensure credentials are refreshed
        AWS.config.credentials.get((err) => {
            if (err) {
                console.error('Error refreshing credentials:', err);
            }
        });
    }, []);

    const handleSendMessage = () => {
        const lexV2 = new AWS.LexRuntimeV2({
            region: process.env.REACT_APP_LEX_BOT_REGION, // Use environment variable
            endpoint: `https://runtime-v2-lex.${process.env.REACT_APP_LEX_BOT_REGION}.amazonaws.com`, // Correct endpoint URL
        });

        const params = {
            botAliasId: process.env.REACT_APP_LEX_BOT_ALIAS_ID, // Use environment variable
            botId: process.env.REACT_APP_LEX_BOT_ID, // Use environment variable
            localeId: process.env.REACT_APP_LEX_BOT_LOCALE_ID, // Use environment variable
            sessionId: userId.current, // Use the unique user ID
            text: inputText,
        };

        lexV2.recognizeText(params, (err, data) => {
            if (err) {
                console.error(err); // Log error details to troubleshoot further
            } else {
                const botMessage = data.messages[0].content;
                setMessages([...messages, { type: 'user', text: inputText }, { type: 'bot', text: botMessage }]);
                setInputText('');

                // Navigate based on the intent
                const intentName = data.interpretations[0].intent.name;
                handleNavigation(intentName);
            }
        });
    };

    const handleNavigation = (intentName) => {
        switch (intentName) {
            case 'About':
                document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
                break;
            case 'Experience':
                document.getElementById('experience').scrollIntoView({ behavior: 'smooth' });
                break;
            case 'Projects':
                document.getElementById('projects').scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                console.log('Intent not recognized');
        }
    };

    return (
        <div className="">
            {!isOpen && (
                <div
                    className="fa fa-comments fa-2x hover:text-white"
                    onClick={() => setIsOpen(true)}
                ></div>
            )}
            {isOpen && (
                <div className="fixed bottom-0 left-0 m-4 z-50 w-80 h-96 bg-[#0a192f] shadow-lg rounded-lg flex flex-col">
                    <div className="bg-[#112240] text-white p-4 rounded-t-lg flex justify-between items-center">
                        <span>AWS LexBot</span>
                        <button className="text-white" onClick={() => setIsOpen(false)}>✖</button>
                    </div>
                    <div className="flex-grow p-4 overflow-y-auto text-white">
                        {messages.map((msg, index) => (
                            <div key={index} className={`mb-2 ${msg.type === 'user' ? 'text-right' : 'text-left'}`}>
                                <div className={`inline-block px-4 py-2 rounded ${msg.type === 'user' ? 'bg-purple-500 text-white' : 'bg-gray-200 text-black'}`}>
                                    {msg.text}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="p-2 border-t border-gray-600 flex">
                        <input
                            type="text"
                            className="flex-grow p-2 border border-gray-600 rounded-l bg-[#112240] text-white"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Say Hello!"
                        />
                        <button
                            className="bg-purple-500 text-white px-4 py-2 rounded-r"
                            onClick={handleSendMessage}
                        >
                            Send
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;



