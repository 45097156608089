import React from 'react';
import CursorFlash from './cursor/flashlight';
import MediPNG from './images/Medilens.png';
import chicagoPNG from './images/chicago.png';
import filesysPNG from './images/filesystem.png';
import compiPNG from './images/compiler.png';
import Chatbot from './chatbot/chatbot';

const App = () => {
    return (
        <div className="bg-darkBlue text-gray-300 min-h-screen p-8 relative">
            <CursorFlash />
            <div className="max-w-7xl mx-auto flex flex-col lg:flex-row">
                {/* Left Side */}
                <div className="lg:w-1/3 p-6">
                    <header className="mb-12">
                        <h1 className="text-5xl font-bold text-gray-100 hover:text-teal-400">Cristian Huerta</h1>
                        <h2 className="text-2xl font-light mt-2"> Entry Software Engineer</h2>
                        <p className="text-lg mt-4">
                            I build innovative software that creates a significant impact on society.
                        </p>
                    </header>
                    <nav className="mb-12">
                        <ul>
                            <li className="text-lg font-light mb-2">
                                <a href="#about" className="hover:text-white">ABOUT</a>
                            </li>
                            <li className="text-lg font-light mb-2">
                                <a href="#experience" className="hover:text-white">EXPERIENCE</a>
                            </li>
                            <li className="text-lg font-light mb-2">
                                <a href="#projects" className="hover:text-white">PROJECTS</a>
                            </li>
                        </ul>
                    </nav>
                    <footer className="flex space-x-4 items-center">
                        <a href="https://github.com/chuerta018" className="text-gray-400 hover:text-white">
                            <i className="fab fa-github fa-2x"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/cristian-huerta-" className="text-gray-400 hover:text-white">
                            <i className="fab fa-linkedin fa-2x"></i>
                        </a>
                        <Chatbot />
                    </footer>
                </div>

                {/* Right Side */}
                <div className="lg:w-2/3 p-6">
                    <main>
                        <section id="about" className="mb-8">
                            <h3 className="text-xl font-bold mb-4">About</h3>
                            <p className="text-base leading-relaxed">
                                After Graduation, I decided to try my hand at creating a custom personal portfolio
                                website, currently under construction, and tumbled head first into the rabbit hole of
                                coding a chatbot (LEX AWS) and Reach's Tailwind CSS.
                                I plan to incorporate a chatbot to my website that assist users with any questions they
                                may have and navigate them through my website.
                                For the time being, I am working on video explanations for the projects listed below.
                            </p>
                            <p className="text-base leading-relaxed mt-4">
                                My main focus these days is engaging in machine learning while gaining invaluable
                                experience with cloud technologies using AWS, such as Lex, S3, and other offerings. I
                                most enjoy building software in the sweet spot where design and engineering meet —
                                things that look good but are also built well under the hood.
                            </p>
                            <p className="text-base leading-relaxed mt-4">
                                When I’m not at the computer, I’m usually rock climbing, at the gym, hanging out with my
                                girlfriend or enjoying the florida spring state parks.
                            </p>
                        </section>
                        <section id="experience" className="mb-8 ">
                            <h3 className="text-xl font-bold mb-4">Experience</h3>
                            <div
                                className="bg-[#0a192f] hover:bg-[#112240] hover:text-teal-400 p-4 rounded-md transition-colors duration-300">
                                <div className="flex mb-4 ">
                                    <p className="text-sm text-[#8892b0] w-1/4 ">Feb 2022 — Present</p>
                                    <div className="w-3/4 ">
                                        <h4 className="text-lg font-semibold text-white  ">Grocery Replenishment
                                            Specialist · Publix</h4>
                                        <p className="text-base leading-relaxed mt-2">
                                            Utilized inventory management systems to track stock levels and optimize
                                            orders. Managed inventory levels by overseeing counts and anticipating
                                            future promotions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="projects" className="mb-8">
                            <h3 className="text-xl font-bold mb-4">Projects</h3>
                            <div className="flex flex-col space-y-8">
                                <div className="flex items-start space-x-4">
                                    <img src={MediPNG} alt="Project Image"
                                         className="w-24 h-24 object-cover rounded-md"/>
                                    <a href="https://youtu.be/VTMT9z8AC54" target="_blank" rel="noreferrer noopener">
                                        <div
                                            className="bg-[#0a192f] hover:bg-[#112240] p-4 rounded-md transition-colors duration-300 w-full">
                                            <h4 className="text-lg font-semibold text-white">MediLens </h4>
                                            <p className="text-base leading-relaxed mt-2">
                                                Engineered MediLens, a medication management Android app focused on
                                                increasing patient safety and medication adherence by incorporating
                                                Machine-Learning models for pill Identification.
                                            </p>
                                            <div className="mt-4 flex flex-wrap space-x-2">
                                            <span
                                                className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Jetpack Compose</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">FastAPI</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">PyTorch</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">MySQL</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">ResNet18</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">EasyOcr</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">YoloV8</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Python</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Roboflow</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Retrofit</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">JSON</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">AES</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Kotlin</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <img src={chicagoPNG} alt="Project Image"
                                         className="w-24 h-24 object-cover rounded-md"/>
                                    <a href="https://youtu.be/9q0Mm7504zE" target="_blank" rel="noreferrer noopener">
                                        <div
                                            className="bg-[#0a192f] hover:bg-[#112240] p-4 rounded-md transition-colors duration-300 w-full">
                                            <h4 className="text-lg font-semibold text-white">Chicago Crime Data
                                                Explorer</h4>
                                            <p className="text-base leading-relaxed mt-2">
                                                A web-based application designed to visualize crime data in Chicago.
                                            </p>
                                            <div className="mt-4 flex flex-wrap space-x-2">
                                            <span
                                                className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">React</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Node.js</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Express</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Oracle SQL</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">JavaScript</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">CSS</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">HTML</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Google Maps API</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">RESTful API</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Chart.js</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <img src={filesysPNG} alt="Project Image"
                                         className="w-24 h-24 object-cover rounded-md"/>
                                    <a href="https://youtu.be/mTOnUKBnoGQ" target="_blank" rel="noreferrer noopener">
                                        <div
                                            className="bg-[#0a192f] hover:bg-[#112240] p-4 rounded-md transition-colors duration-300 w-full">
                                            <h4 className="text-lg font-semibold text-white">WAD File System</h4>
                                            <p className="text-base leading-relaxed mt-2">
                                                A custom file system that parses WAD objects, enhancing the storage and
                                                retrieval efficiency for large datasets.
                                            </p>
                                            <div className="mt-4 flex flex-wrap space-x-2">
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">C++</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Linux</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">FUSE</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">File System</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Data Structures</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Algorithms</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Parser</span>
                                                <span
                                                    className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Directory</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="flex items-start space-x-4">
                                <img src={compiPNG} alt="Project Image"
                                     className="w-24 h-24 object-cover rounded-md"/>
                                <a href="https://youtu.be/yiB48SkZFgw" target="_blank" rel="noreferrer noopener">
                                    <div
                                        className="bg-[#0a192f] hover:bg-[#112240] p-4 rounded-md transition-colors duration-300 w-full">
                                        <h4 className="text-lg font-semibold  text-white">Custom Language
                                            Compiler</h4>
                                        <p className="text-base leading-relaxed mt-2">
                                            Developed comprehensive components of a compiler using Java,
                                            specializing in
                                            syntax parsing, type checking, and exception handling to ensure proper
                                            code
                                            performance.
                                        </p>
                                        <div className="mt-4 flex flex-wrap space-x-2">
                                            <span
                                                className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Java</span>
                                            <span
                                                className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">AST</span>
                                            <span
                                                className="bg-[#112240] text-[#64ffda] rounded-lg px-3 py-1 text-sm font-medium">Dynamic Compilation</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </section>

                        <section id="footer" className="mb-8">
                            <div className="flex flex-col space-y-8">
                                <div className="flex items-start space-x-4">
                                    <p className="text-base font-light leading-relaxed">
                                        Website Design inspired by <a href="https://brittanychiang.com/"
                                                                      className="text-purple-500 hover:underline">Brittany
                                        Chiang</a>,
                                        The website is built on React, Tailwind CSS and hosted on AWS S3 bucket. Note I
                                        did not fork or clone her repository for website design.

                                    </p>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default App;

