import React, { useEffect } from 'react';

const CursorFlash = () => {
    useEffect(() => {
        const handleMouseMove = (event) => {
            const cursorFlash = document.getElementById('cursor-flash');
            cursorFlash.style.left = `${event.clientX}px`;
            cursorFlash.style.top = `${event.clientY}px`;
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div
            id="cursor-flash"
            className="fixed pointer-events-none w-0 h-0 rounded-full border-0 transition-transform transform -translate-x-1/2 -translate-y-1/2"
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0)', // Ensure the background is fully transparent
                boxShadow: '0 0 150px 150px rgba(59, 130, 246, 0.1)', // Adjust shadow to be visible
            }}
        ></div>
    );
};

export default CursorFlash;

